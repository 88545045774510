import Vue from 'vue';
import VueRouter from 'vue-router';
import ShareView from './components/ShareView.vue'
import HomeView from './components/HomeView.vue'
import DeniedView from './components/DeniedView.vue'
import UnitTestView from './components/UnitTestView.vue'

Vue.use(VueRouter);

const routes = [
    { path: '/', name:"home",component: HomeView},
    { path: '/denied', name:"denied",component: DeniedView},
]
 
// Vue 라우터 인스턴스 생성
const router = new VueRouter({
    mode: 'history',
    routes: routes
});

export default router;
