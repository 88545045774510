<template>
    <v-main>
      <v-container style="width:500px;text-align: center;margin auto 0;">
        <v-row class='justify-center'>
            <v-col xs="6">
                
                <v-text-field :value="expire"
                        filled 
                        rounded 
                        dense 
                        single-line 
                        >
                </v-text-field>
         
            </v-col>
            <v-col xs="6">
              
    
                <v-btn :loading="isLoading" color="primary" @click="performAction" v-bind:disabled="isLoading">
                    <v-icon>mdi-refresh</v-icon> Start
                </v-btn>
                <v-btn color="warning" @click="performCancel" v-show="isLoading">
                     Stop
                </v-btn>
    
            </v-col>
        </v-row>
      </v-container>
      <v-container>
        <span class="display-3">{{index}}</span>
      </v-container>
      <v-container>
        <span class="display-2">{{elapsedDuration}}</span>
      </v-container>
    </v-main>
</template>

<script>
  
  const axios = require('axios');
  const moment = require("moment-timezone");
  var MAP_ID="", LOCALE;

  export default {
    name: "Query",
    data() {
       
     
        return {
            sendInterval:null
            ,isLoading: false
            ,expire: 3600
            ,index:-1
            ,elapsedDuration:"0일 0시간 0분 0초"
            ,startDate: undefined
        }
    },
    created() {
      const qry=this.$route.query;

      if(Object.keys(qry).length == 0){
        this.$router.push({name:"denied",query: {code: 400 }});
      }else{
        MAP_ID = qry.map_id;
       

       
      }
    },
    beforeMount() {
      
    },
    mounted() {
      // const qry=this.$route.query;
      // console.log(qry);
      // console.log(Object.keys(qry).length);

      // if(Object.keys(qry).length == 0){

      // }else{
      //   this.getData(this.$route.query);
      // }
      
      this.$nextTick(() => {
        // 모든 화면이 렌더링된 후 실행.

        
      })
    },
    updated() {
      this.$nextTick(function () {
        // 모든 화면이 렌더링된 후 실행합니다.
      })
    },
    beforeUnmount() { 
      
    },
    watch: {
      // width(newWidth){
      //   console.log(newWidth);
      //   this.width = newWidth;
      // }
    },
   
    methods: {
      onLoad(vue)
      {
        //this.map = vue;
      },
      onWindowLoad(that) {
      },
      performAction() {
        this.isLoading = true;
        this.startDate = new Date();
        const coords = this.getDummyData();
        console.log("__start()____");
        this.index=0;

        this.sendInterval = setInterval(() => {
            if((coords.length - 1) == this.index){
                console.log("__end()____");
                this.clearSendInterval();
                return;
            }
            let body = coords[this.index];
            this.elapsedDuration = this.duration();
            this.sendCoord(body);
            
            this.index++;
          }, 5000);
      }
      ,performCancel() {
        this.clearSendInterval();
      }
      ,clearSendInterval(){
        this.isLoading = false;
        clearInterval(this.sendInterval);
      }
      ,duration(){
            
            const endDate = new Date(); // 2022-06-25T23:00:00.000Z
            const day = this.startDate.getTime() - endDate.getTime();
            const dDay = Math.floor(Math.abs(day / (1000*3600*24)));
            const dHour = Math.floor(Math.abs((day / (1000*3600)) % 24));
            const dMin = Math.floor(Math.abs((day / (1000*60)) % 60));
            const dSec = Math.floor(Math.abs(day / 1000 % 60));
            
        return `${dDay}일 ${dHour}시간 ${dMin}분 ${dSec}초`;
      }
      ,async sendCoord(coord) {
        const body = {type:"coords",coords:coord,expire:this.expire};
        const options = {
            method: 'POST',
            url: 'http://localhost:3000/rest/share/pub/'+MAP_ID,
            params: { 'api-version': '3.0' },
            headers: {
                'content-type': 'application/json',
                "Access-Control-Allow-Origin": `http://localhost:8080`,
                'Access-Control-Allow-Credentials':"true", 
            },
            data: body,
        };
 
        axios
            .request(options)
            .then(function (response) {
                console.log(response.data);
            })
            .catch(function (error) {
                console.error(error);
            });
          
       
      },
      getDummyData(){
        return  [
            {'lng':127.027499,'lat':37.573663},
            {'lng':127.028787,'lat':37.578357},
            {'lng':127.025353,'lat':37.579649}, 
            {'lng':127.019002,'lat':37.579581}, 
            {'lng':127.016341,'lat':37.578357}, 
            {'lng':127.014024,'lat':37.576520}, 
            {'lng':127.009904,'lat':37.574275}, 
            {'lng':127.005784,'lat':37.573731}, 
            {'lng':127.001664,'lat':37.573595}, 
            {'lng':126.995399,'lat':37.572915}, 
            {'lng':126.989734,'lat':37.571282}, 
            {'lng':126.989047,'lat':37.569989}, 
            {'lng':126.985957,'lat':37.568084}, 
            {'lng':126.982266,'lat':37.567608}, 
            {'lng':126.976602,'lat':37.567948}, 
            {'lng':126.974628,'lat':37.569513}, 
            {'lng':126.968448,'lat':37.568697}, 
            {'lng':126.968362,'lat':37.572302}, 
            {'lng':126.985700,'lat':37.578901}, 
            {'lng':126.988532,'lat':37.579785}, 
            {'lng':126.986901,'lat':37.582642}, 
            {'lng':126.983897,'lat':37.587063}, 
            {'lng':126.982524,'lat':37.588967}, 
            {'lng':126.982266,'lat':37.592436}, 
            {'lng':126.984241,'lat':37.598148}, 
            {'lng':126.989390,'lat':37.596720}, 
            {'lng':126.991450,'lat':37.597740}, 
            {'lng':126.993339,'lat':37.598693}, 
            {'lng':126.997115,'lat':37.597604}, 
            {'lng':126.999347,'lat':37.595020}, 
            {'lng':127.001407,'lat':37.593660}, 
            {'lng':127.003982,'lat':37.591960}, 
            {'lng':127.004668,'lat':37.588695}, 
            {'lng':127.003638,'lat':37.587539}, 
            {'lng':127.000892,'lat':37.585703}, 
            {'lng':126.999948,'lat':37.584478}, 
            {'lng':126.998488,'lat':37.582710}, 
            {'lng':126.996772,'lat':37.581418}, 
            {'lng':126.996600,'lat':37.578901}, 
            {'lng':126.997115,'lat':37.576384}, 
            {'lng':126.993596,'lat':37.577132}, 
            {'lng':126.990764,'lat':37.577336}, 
            {'lng':126.987588,'lat':37.577404}, 
            {'lng':126.985474,'lat':37.576624}, 
            {'lng':126.983468,'lat':37.575976}, 
            {'lng':126.980035,'lat':37.575568}, 
            {'lng':126.976001,'lat':37.574411}, 
            {'lng':126.973941,'lat':37.573595}, 
            {'lng':126.969649,'lat':37.574207}, 
            {'lng':126.967418,'lat':37.572234}, 
            {'lng':126.963126,'lat':37.568969}, 
            {'lng':126.962096,'lat':37.566792}, 
            {'lng':126.954457,'lat':37.569105}, 
            {'lng':126.950938,'lat':37.568901}, 
            {'lng':126.948106,'lat':37.566043}, 
            {'lng':126.942527,'lat':37.563322}, 
            {'lng':126.939866,'lat':37.562302}, 
            {'lng':126.935489,'lat':37.562710}, 
            {'lng':126.927764,'lat':37.565363}, 
            {'lng':126.922528,'lat':37.565907}, 
            {'lng':126.919181,'lat':37.566724}
        ]
      },
      update(){
        
      }
    },
    
  }
</script>
