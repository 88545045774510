<template>
    <v-main>
      <v-container>
        
       
        <v-alert
          outlined
          type="success"
          text
          >
          <h2>code: {{ $route.query.code }}</h2>
        </v-alert>
       
  
        
      </v-container>
    </v-main>
</template>

<script>
  export default {
    name: "Query",
    created() {
    
    },
    beforeMount() {
      
    },
    mounted() {
      // const qry=this.$route.query;
      // console.log(qry);
      // console.log(Object.keys(qry).length);

      // if(Object.keys(qry).length == 0){

      // }else{
      //   this.getData(this.$route.query);
      // }
      
      this.$nextTick(() => {
        // 모든 화면이 렌더링된 후 실행.

        
      })
    },
    updated() {
      this.$nextTick(function () {
        // 모든 화면이 렌더링된 후 실행합니다.
      })
    },
    beforeUnmount() { 
      
    },
    watch: {
      // width(newWidth){
      //   console.log(newWidth);
      //   this.width = newWidth;
      // }
    },
   
    methods: {
      onLoad(vue)
      {
        //this.map = vue;
      },
      onWindowLoad(that) {
      },
   
      getData(query) {
        
      }
      ,
      update(){
        
      }
    },
    
  }
</script>
