import Vue from 'vue'
import App from './App.vue'
import router from './router'
import naver from 'vue-naver-maps';
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import VueSimpleAlert from "vue-simple-alert";

Vue.config.productionTip = false

Vue.use(naver, {
  clientID: '1cnevujmms',
  useGovAPI: false, //공공 클라우드 API 사용 (선택)
  subModules:'' // 서브모듈 (선택)
});
Vue.use(VueSimpleAlert);

new Vue({
  router,
  vuetify,
  
  render: h => h(App),
  i18n
}).$mount('#app')

