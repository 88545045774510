<template>
    <div>

      <div class="scheme-box" v-show="!isError">
				<div class="scheme-sub-box">
					<div class="profile-image">
						<img src="@/assets/images/logo.png" class="logo"/>
            
					</div>
					<div class="info-txt">
            

						<ul>
							<li class="scheme-time">
              
                  <v-icon v-show="isSocketOpen" class="socket-status-ico" color="success" size="small">mdi-check-circle</v-icon>
                  <v-icon v-show="!isSocketOpen" class="socket-status-ico" color="error" size="small">mdi-alert</v-icon>
                
                <span>{{ $t('label.elapseTime') }} : </span><span class="remain-time">{{ elapseValues.elapsed }}</span></li>
							<li class="scheme-txt">{{schemeText}}</li>
						</ul>
					</div>
				</div>
				<v-btn @click="goSchemeLink()"  
              class="btn-download" 
              v-show="!isInstalledApp" block rounded size="x-large">{{ $t("button.download") }} </v-btn>
              
        <v-btn @click="goSchemeLink()" class="btn-download" v-show="isInstalledApp" block rounded size="x-large">{{ $t("button.openapp") }}</v-btn>
			</div>
      
      <naver-maps
        class="map-style"
        style="width:100%;height:100%;position: absolute;"
        :mapOptions="mapOptions"
        :initLayers="initLayers"
        @load="onLoad" v-if="this.$route.query.map_id !== undefined">

        <naver-info-window
          class="info-window"
          @load="onWindowLoad"
          :isOpen="info"
          :marker="marker">
          <div class="info-window-container">
            <h1>{{hello}}</h1>
          </div>
        </naver-info-window>
        <naver-marker :lat="37.5666612" :lng="126.9783785" @click="onMarkerClicked" @load="onMarkerLoaded"/>
        <naver-circle :lat="37.5666612" :lng="126.9783785" :radius="500" @load="onCircleLoaded"/>
        <!-- <naver-rectangle :south="36" :north="38" :west="126" :east="128"/>
        <naver-ellipse :bounds="{south:36,north:38,west:126,east:128}"/>
        <naver-polygon :paths="[[{lat:37,lng:127},{lat:38,lng:127},{lat:38,lng:129},{lat:37,lng:128}]]"/> -->
        <naver-polyline :path="pathList" :options="polylineOptions"  @load="onPolylineLoaded"/>
        <!-- <naver-ground-overlay
          :url="'//logoproject.naver.com/img/img_about.gif'"
          :bounds="{south:36.7,north:36.9,west:126.5,east:127.5}"/> -->
      </naver-maps>


      <v-card
        class="mx-auto floating-card"
        max-width="344"
        v-show="isExpired"
        >
          <v-img
            src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
            height="200px"
          ></v-img>
          <v-card-title>
            {{ $t("message.expired") }} {{message}}
            
          </v-card-title>
         
      </v-card>
  </div>
</template>

<script>
  import api from '../services/api'
  const deeplink = require("../services/browser-deeplink");
  import Stomp from 'webstomp-client'
  import SockJS from 'sockjs-client'

  var baseURL="";
  var apiURL="";

  export default {
    name: 'ShareView',
    data() {
      return {
        show: false,
        isError: false,
        isExpired: false,
        timer: null,
        schemeInterval:null,
        isInstalledApp:false,
        remainTime:"0",
        schemeText:"",
        info: false,
        marker: null,
        map: null,
        circle: null,
        ployline: null,
        pathList:[],
        isCTT: false,
        elapseValues:{startTime:"",endTime:"",elapsed:""},
        elapseInterval:null,
        mapId:"",
        message: "",
        ws:undefined,    
        stompClient:null,
        socketReloadTimerId: undefined,
        isSocketOpen: false,
        reconnectSocket:0,
        currentCoord:{lat:37.5666612, lng:126.9783785},
        mapOptions: {
          lat: 37.5666612,
          lng: 126.9783785,
          zoom: 10,
          zoomControl: true,
          zoomControlOptions: {position: 'LEFT_TOP'},
          mapTypeControl: true,
        },
        circleOptions:{
          fillColor:"#fc9803",
          fillOpacity:"0.1",
          strokeColor:"#fc9803",
          strokeOpacity:"0.6"
        },
        polylineOptions:{
          strokeColor:"#b70000",
        },
        allowHeaders: { 
                "Access-Control-Allow-Origin": `http://192.168.0.39:3000`,
                'Access-Control-Allow-Credentials':"true", 
              },
        initLayers: ['BACKGROUND', 'BACKGROUND_DETAIL', 'POI_KOREAN', 'TRANSIT', 'ENGLISH', 'CHINESE', 'JAPANESE']
      }
    },
    computed: {
      hello() {
        return `Hello, World! × ${this.count}`;
      }
    },
    beforeCreate () {
      deeplink.setup({
          fallback: false,
          iOS: {
              appName: "funch",
              appId: process.env.VUE_APP_APP_STORE_APP_ID,
              storeUrl: process.env.VUE_APP_APP_STORE_URL,

          },
          android: {
              appId: process.env.VUE_APP_PLAY_STORE_APP_ID,
              storeUrl: process.env.VUE_APP_PLAY_STORE_URL,

          }
      });
    },
    created () {

      const qry=this.$route.query;
      if (process.env.NODE_ENV == "production") {
        baseURL = 'https://' + process.env.VUE_APP_API_HOST;
      } else if (process.env.NODE_ENV == "development") {
        baseURL =  'http://' + process.env.VUE_APP_API_HOST;
      }

      if(Object.keys(qry).length == 0){
        this.$router.push({name:"denied",query: {code: 400 }});
      }else{
        this.mapId = qry.map_id;
        const numString = this.mapId.slice(-6, this.mapId.length);
        if(this.isNumeric(numString)==false || numString.length!=6){
          this.$router.push({name:"denied",query: {code: 405 }});
        }else{
          this.getData();
          //this.initWebsocket();

        }
      }
    },
    beforeMount() {
      
    },
    mounted() {
      this.$nextTick(() => {
        // 모든 화면이 렌더링된 후 실행.
      })
    },
    updated() {
      this.$nextTick(function () {
        // 모든 화면이 렌더링된 후 실행합니다.
      })
    },
    beforeUnmount() { 
      
    },
    unmounted(){
      this.ws.close();
    },
    watch: {
      currentCoord(newCoord){
        //console.log(newCoord.lat,newCoord.lng);
        this.marker.setPosition({lat:newCoord.lat,lng:newCoord.lng});
        this.map.morph({lat:newCoord.lat,lng:newCoord.lng});
        this.circle.setCenter({lat:newCoord.lat,lng:newCoord.lng});
        this.pathList.push({lat:newCoord.lat,lng:newCoord.lng});
        this.ployline.setPath(this.pathList);
      }
      
    },
   
    methods: {
      initWebsocket(){

        try {
            var host = process.env.VUE_APP_SOCKET_HOST+"/ws-stomp";

            if (process.env.NODE_ENV == "production") {
            } else if (process.env.NODE_ENV == "development") {
            }
            var protocol = (process.env.NODE_ENV == "production") === 'https:' ? 'wss:' : 'http:';

            //console.log(host);
            // socket 연결
            this.ws = new SockJS(protocol+"//"+host)
            this.stompClient = Stomp.over(this.ws)
            this.stompClient.reconnect_delay = 5000;

            this.stompClient.connect({}, frame=> {
                console.log("success", frame.command)
                
                this.isSocketOpen = true;

                this.stompClient.subscribe("/sub/share/room/"+this.mapId, res=>{
                    let jsonBody = JSON.parse(res.body)

                    if(frame.command=="CONNECTED"){
                        //console.log(jsonBody.lat)
                        this.currentCoord = jsonBody;
                    }else if(frame.command=="MESSAGE"){

                    }else if(frame.command=="SUBSCRIBE"){

                    }

                })
            }, err=>{
                console.log("fail", err)
            })
       
        } catch(err) {
          console.log(err);
        }
      },
      reConnect() {
        var self = this;
        let delay = 1000;

        let timerId = setTimeout(function tick() {
          if(self.isSocketOpen==true){
            self.reconnectSocket=0;
            return;
          }
          if(self.reconnectSocket==3){
            return;
          }
          self.initWebsocket();
          timerId = setTimeout(tick, delay); // (*)
          self.reconnectSocket++;
        }, delay);
      },
      
   
      onLoad(vue)
      {
          this.map = vue;
      },
      onWindowLoad(that) {
      },
      onMarkerClicked(event) {
          this.info = !this.info;
      },
      onMarkerLoaded(vue) {
          this.marker = vue.marker;
      },
      onCircleLoaded(vue){
          this.circle = vue;
      },
      onPolylineLoaded(vue){
        this.ployline = vue;
      },
      async getPolyList(){
        var self = this;
        
        try {
          var headers ={};
          if(process.env.NODE_ENV == "development"){
            headers = this.allowHeaders;
          }
          const res = await api.post(baseURL +  process.env.VUE_APP_EP_SHARE_ROUTES + "/"+this.mapId, {
              headers: headers,
            })
          if(res.data.code==200){
              let list = res.data.list;
              list.forEach(function each(path){
                 self.pathList.push({lat:path.lat,lng:path.lng});
              });
            }
        } catch (error) {
          console.error(error)
        }
      },
      async getData() {
        console.log("getData > " + baseURL);
        try {
          var headers ={};
          if(location.protocol != 'https:'){
            headers = this.allowHeaders;
          }
          var data = {"tz":"Asia/Seoul","mapId":this.mapId};
          const res = await api.post(baseURL +process.env.VUE_APP_EP_SHARE_INFO, data)
          console.log(res.data)  

          if(res.data.code==200){
              
              this.setShareData(res.data.data);
            }
        } catch (error) {
          console.error(error)
        }
      }
      ,setShareData(result){
          if(result==undefined){

            this.isExpired = true;
            this.isError = true;
          }else{
            this.elapseValues.startTime = result.startDt;
            this.elapseValues.endTime = result.endDt;
            this.elapseValues.userid = result.pubUserid;

            if(result.isExpired==0){
              this.isExpired = true;
            }else{
              this.isExpired = false;
              this.initWebsocket();
            }
            if(this.isExpired == false){
              this.reloadMap(result);
              this.setElapseTimers();
              
              var milliseconds = new Date(this.elapseValues.endTime).getTime();
              if(deeplink.open(process.env.VUE_APP_DEEPLINK_SCHEME + "/?shareId="+this.mapId+"&userid="+this.elapseValues.userid+"&endDt="+milliseconds)){
                  this.isInstalledApp = true;
              }else{
                  this.isInstalledApp = false;
              }
            }else{
              this.isInstalledApp = false;
            }
            console.log(this.isInstalledApp);
          }
      }
      ,reloadMap(result){

          setTimeout(() => {
            this.currentCoord = {lat:result.lat, lng:result.lng};
            this.marker.setPosition(this.currentCoord);
            this.map.setZoom(18);

            this.circle.setRadius(200);
            this.circle.setOptions(this.circleOptions);
            this.ployline.setPath(this.pathList);
            this.getPolyList();

          }, 1000)
      }
      ,clearPolyInterval(){
        
        clearInterval(this.polyInterval);
      }
      ,setElapseTimers() {
        this.elapseInterval = setInterval(() => {
          this.elapseValues.elapsed = this.getCalcTime();

          if(this.elapseValues.elapsed==0){
            this.isExpired = true;
            clearInterval(this.elapseInterval);
          }
        }, 1000);

      }
      ,getCalcTime(){
          const masTime = new Date(this.elapseValues.endTime);
          const todayTime = new Date();
          
          const diff = masTime - todayTime;

          const diffDay = Math.floor(diff / (1000*60*60*24));
          const diffHour = Math.floor((diff / (1000*60*60)) % 24);
          const diffMin = Math.floor((diff / (1000*60)) % 60);
          const diffSec = Math.floor(diff / 1000 % 60);

          if(diffDay<=0 && diffHour<=0 && diffMin<=0 && diffSec<=0){
            return 0;
          }
          return `${diffDay}일 ${diffHour}시간 ${diffMin}분 ${diffSec}초`;
      },
      
      goSchemeLink(){
        if(this.isInstalledApp){
          var milliseconds = new Date(this.elapseValues.endTime).getTime();
          deeplink.open(process.env.VUE_APP_DEEPLINK_SCHEME + "/?shareId="+this.mapId+"&userid="+this.elapseValues.userid+"&endDt="+milliseconds);
        }else{
          console.log(deeplink.getValue());
        }
        return false;
      },
      // 인터벌, 타이머 삭제
      clearTimer(){
        clearInterval(this.schemeInterval);
        clearTimeout(this.timer);
      },
      isNumeric(value) {
        return /^-?\d+$/.test(value);
      },
      update(){

      }
    },
    
  }
</script>
<style scoped>
  .info-window-container {
    padding: 10px;
    width: 300px;
    height: 100px;
  }
  .map-style{
  position: absolute;
  width: 100%; 
  height: 100%;
  margin:0 auto;
}
.floating-card{
  position: absolute;
  z-index:2000;
  top: 30px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.scheme-box{
	background-color: white;
	width:300px;
	max-height:150px;
	z-index:1002;

	position: fixed;
	bottom:10px;
	
	left: calc(50% - 160px);
	
	padding: 10px;
	border-radius: 5px;
	filter: drop-shadow(5px 5px 5px rgba(177, 177, 177,0.5));
	
}
.scheme-box .scheme-sub-box {
	display: flex;
}
.scheme-box  .scheme-sub-box > div {
	float: left;
    clear: none; 
}

.scheme-box .scheme-sub-box .profile-image {
	padding-right: 10px;
}
.scheme-box .scheme-sub-box .info-txt{
	text-align: left;
}
.scheme-box .scheme-sub-box .info-txt .scheme-time{
  padding-top:3px;
}
.scheme-box .scheme-sub-box .info-txt .scheme-time span{
  font-weight: 900;
  font-size: 14px;
}
.scheme-box .profile-image img {
	max-width:40px;
}
.scheme-box .scheme-item {
	color:#666666;
	font-size: 12px;
}

.scheme-box ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}
.scheme-box ul li{
    line-height: 160%;
}
.scheme-box ul li,  .scheme-box ul li span{
    color:#666666;
	font-size: 12px;
}
.scheme-box ul li .remain-time {
    color:rgb(0, 150, 255);
}
.scheme-box .btn-download {

	/* width:100%;
    top:50%;
    margin-top:5px; */
    background-color:rgb(255, 147, 0);
    color: #fff;
    /* border:none;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius:20px;
    clear:none; */
}
.socket-status-ico {
  padding-bottom:4px;
  padding-right:3px;
}
</style>